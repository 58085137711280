import React, { useState, useEffect } from 'react';
// import { useSearchParams } from 'react-router-dom';
import { MdOutlineZoomOutMap } from "react-icons/md";
import { MdOutlineZoomInMap } from "react-icons/md";
import { AiOutlineMinus } from "react-icons/ai";

const FloatingIframeButton = ({ urlRef, buttonImg }) => {
    const [isIframeVisible, setIsIframeVisible] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [srcUrl, setSrcUrl] = useState('');
    // const [searchParams] = useSearchParams();
    // const initialRender = useRef(true); // 标记是否是初次渲染

    useEffect(() => {
        // if (initialRender.current) {
        // initialRender.current = false;

        const currentUrl = urlRef;

        if (currentUrl.includes("float")) {
            const updatedUrl = currentUrl.replace(/float/g, 'scene');
            setSrcUrl(updatedUrl);
        } else {
            setSrcUrl(currentUrl);
        }
        // }
    }, [urlRef]);

    // Handle showing the iframe and hiding the button
    const handleButtonClick = () => {
        setIsIframeVisible(true);
    };

    // Handle minimizing the iframe
    const handleMinimizeClick = () => {
        setIsIframeVisible(false);
    };

    // Toggle fullscreen mode
    const handleFullscreenClick = () => {
        setIsFullscreen(!isFullscreen);
    };

    // Iframe container styles
    const iframeContainerStyle = {
        position: 'fixed', // 保持浮动效果
        bottom: isFullscreen ? '0' : '50px', // 窗口状态时距离底部的距离
        right: isFullscreen ? '0' : '20px', // 窗口状态时距离右侧的距离
        width: isFullscreen ? '100vw' : '350px',
        height: isFullscreen ? '100vh' : '600px',
        backgroundColor: 'white',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
        borderRadius: isFullscreen ? '0' : '8px',
        overflow: 'hidden',
        display: isIframeVisible ? 'block' : 'none',
        zIndex: 1000,
    };

    // Bug 修复：检查 srcUrl 是否为空，如果为空则不渲染 iframe
    const iframeContent = srcUrl ? (
        <iframe
            id="iframe-wrapper"
            src={srcUrl}
            allow="microphone"
            border="0"
            className="w-full h-full"
            title='iframeButton'
        ></iframe>
    ) : null;

    return (
        <>
            {!isIframeVisible && (
                <div
                    id="floating-button"
                    style={{
                        position: 'fixed',
                        bottom: '200px',
                        right: '20px',
                        width: '60px',
                        height: '60px',
                        backgroundColor: '#007bff',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        fontSize: '18px',
                        cursor: 'pointer',
                        zIndex: 999,
                        backgroundImage: `url(${buttonImg})`, // 添加图片背景
                        backgroundSize: 'cover', // 图片铺满整个容器
                        backgroundRepeat: 'no-repeat', // 不重复图片
                        backgroundPosition: 'center', // 图片居中
                        border: '1px solid white', // 添加白色边框
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',

                    }}
                    onClick={handleButtonClick}
                >
                    {/* 点击 */}
                </div>
            )}
            <div id="iframe-container" style={iframeContainerStyle}>
                <div
                    id="minimize-button"
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '5px',
                        width: '20px',
                        height: '20px',
                        backgroundColor: '#ecc100',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        color: 'black',
                        fontSize: '12px',
                        cursor: 'pointer',
                        zIndex: 1000,
                    }}
                    onClick={handleMinimizeClick}
                >
                    {/* 关闭 */}
                    <AiOutlineMinus />
                </div>

                {/* Fullscreen Button */}
                <div
                    id="fullscreen-button"
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '30px',
                        width: '20px',
                        height: '20px',
                        backgroundColor: '#38b045',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        color: 'black',
                        fontSize: '12px',
                        cursor: 'pointer',
                        zIndex: 1000,
                    }}
                    onClick={handleFullscreenClick}
                >
                    {/* {isFullscreen ? '退出全屏' : '全屏窗口'} */}
                    {isFullscreen ? <MdOutlineZoomInMap /> : <MdOutlineZoomOutMap />}
                </div>

                {/* Iframe Content */}
                {iframeContent}
                {/* <div style={{ width: '100%', height: '100%' }}>
                    <Scene />
                </div> */}
            </div>
        </>
    );
};

export default FloatingIframeButton;
