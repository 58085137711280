import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import HomePage from './HomePage';
import ProductPage from './ProductPage';
import PricePage from './PricePage';
import CompanyPage from './CompanyPage';
import ServicePage from './ServicePage';
import LoginPage from './LoginPage';

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/price" element={<PricePage />} />
        <Route path="/company" element={<CompanyPage />} />
        <Route path="/service" element={<ServicePage />} />
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </Router>
  );
};

export default App;
