import React from 'react';
import './index.css';
import Logo from './images/logo.png'; // 引入图像

const Header = () => {
  return (
    <header className="w-full h-12 bg-gradient-to-r from-transparent via-transparent to-black fixed top-0 z-10 backdrop-filter backdrop-blur-sm">
      <nav className="flex items-center justify-between px-4">
        <div className="">
          <a href="/">
            <img src={Logo} alt="Logo" className="h-12" />
          </a>
        </div>
        <ul className="flex space-x-6">
          <li><a href="/" className="text-white hover:text-gray-300 font-bold">首页</a></li>
          <li><a href="/product" className="text-white hover:text-gray-300 font-bold">产品</a></li>
          <li><a href="/price" className="text-white hover:text-gray-300 font-bold">定价</a></li>
          <li><a href="/service" className="text-white hover:text-gray-300 font-bold">客户服务</a></li>
          <li><a href="/company" className="text-white hover:text-gray-300 font-bold">公司</a></li>
          <li><a href="/login" className="text-white hover:text-gray-300 font-bold">登录</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
