import React, { useState } from 'react';
import QRCodeImg from './images/群二维码.jpg'; // 引入图像

const ServicePage = () => {
  const [showImage, setShowImage] = useState(false);

  const toggleImage = () => {
    setShowImage(!showImage);
  };

  return (
    <main className="snap-container">
      <section className="snap-section">
        <div className="w-full h-full relative">
          <iframe
            src="https://vc.check-look.com/iframeWrapper?va=UE_you7"
            allow="microphone"
            border="0"
            className="w-full h-full rounded-lg"
            title='iframeService'
          >
            您的浏览器不支持iframe元素。
          </iframe>
        </div>
      </section>
      <div className="fixed bottom-1/2 left-5 w-44 flex flex-col items-center">
        {showImage && (
          <div className="max-w-[160px] mb-2">
            <img
              src={QRCodeImg} // 替换为实际的图片路径
              alt="Customer Service"
              className={`w-full h-full rounded-lg`}
            />
          </div>
        )}
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onMouseEnter={toggleImage}
          onMouseLeave={toggleImage}
          onClick={toggleImage}
        >
          客服群
        </button>
      </div>

    </main>
  );
};

export default ServicePage;
