import React, { useRef, useState } from 'react'; // 导入useState
import './HomePage.css'; // 引入新的CSS文件

const PricePage = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true); // 初始状态为静音

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(!isMuted); // 切换图标状态
    }
  };

  return (
    <main className="snap-container">
      <section className="snap-section flex items-center">
        <div className="w-9/12 ml-8 mr-2 video-container relative">
          <video
            ref={videoRef} // 使用 ref 来引用视频元素
            src="/media/部署方案视频.mp4"
            autoPlay
            loop
            muted={isMuted} // 根据状态设置静音
            disablePictureInPicture
            playsInline
            className="w-full h-full object-cover rounded-2xl"
          />
          <button
            className="absolute top-5 left-5 bg-white text-black px-2 py-1 rounded-full"
            onClick={toggleMute} // 使用 toggleMute 函数来切换静音状态
          >
            {isMuted ? '🔇' : '🔊'}
          </button>
        </div>

        <div className={`w-3/12 text-white mr-8 ml-2`}>
          <h2 className="text-3xl font-bold my-5">多种部署方式可供选择</h2>
          <p>您能够将 Check-look 几乎可以部署在任意环境中，无论是移动端、微信、小程序、公众号、还是本地的大屏幕，抑或是集成在自有业务的系统中，我们都将是您不二的选择。</p>
        </div>
      </section>

      <section className="snap-section">
        <div className="flex flex-col items-center justify-center h-screen text-white" style={{ textAlign: 'center' }}>
          <h1 className="text-3xl font-bold m-8">准备好踏上您的数字化转型之旅了吗？<br></br>借助Check-look，吸引您的受众并超越传统沟通的界限。<br></br>为您的企业赋能，与专注于您成功的合作伙伴共同前行。</h1>
          <div className="w-50%">
            <table className="table-auto text-white">
              <tbody>
                <tr>
                  <td className="border px-4 py-2">50元流量包</td>
                  <td className="border px-4 py-2">100条回答</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">100元流量包</td>
                  <td className="border px-4 py-2">210条回答</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">200元流量包</td>
                  <td className="border px-4 py-2">430条回答</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">500元流量包</td>
                  <td className="border px-4 py-2">1100条回答</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">1800元流量包</td>
                  <td className="border px-4 py-2">4000条回答</td>
                </tr>
              </tbody>
            </table>
          </div>

          <p className="text-sm my-3">新用户注册免费赠送50条回答</p>
        </div>
      </section>
    </main>
  );
}

export default PricePage;
