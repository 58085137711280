import React, { useRef, useState, useEffect } from 'react';
import './HomePage.css'; // 引入新的CSS文件
import FloatingIframeButton from "./FloatingIframeButton";
import ZhuangJiongwen from "./images/庄煚文.jpg"
import ZhuangJia from "./images/庄稼.jpg"
import WangZhuqing from "./images/王竹青.jpg"

const CompanyPage = () => {
  const sectionRefs = useRef([]);
  const [isVisible, setIsVisible] = useState([]);
  const [hoveredMember, setHoveredMember] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      sectionRefs.current.forEach((ref, index) => {
        const rect = ref.getBoundingClientRect();
        if (rect.top <= window.innerHeight) {
          setIsVisible(prevVisible => {
            const newVisible = [...prevVisible];
            newVisible[index] = true;
            return newVisible;
          });
        } else {
          setIsVisible(prevVisible => {
            const newVisible = [...prevVisible];
            newVisible[index] = false;
            return newVisible;
          });
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <main className="snap-container relative">
      <FloatingIframeButton urlRef="https://vc.check-look.com/iframeWrapper?va=UE_you11" buttonImg="/models/UE_you11/cover.png"/>

      <section className="snap-section">
        <div className="w-full h-full relative">
          <video src="/media/CN_OP.mp4"
            autoPlay
            loop
            muted
            disablePictureInPicture
            playsInline
            className="w-full h-full object-cover"
            controls={false} />
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-85"></div>
        </div>
      </section>

      <div className="absolute top-0 left-0 w-full transform translate-y-3/4 justify-center">
        <div className={`text-white text-center m-8 leading-relaxed z-10 ${isVisible[0] ? 'animate-slide-up' : ''}`} ref={el => sectionRefs.current[0] = el}>
          <h2 className="text-3xl font-bold m-10 p-5">
            我们的价值观：引领互动视觉传播的创新策略。
          </h2>
          <p className='text-xl leading-relaxed'>
            在未来的人工智能时代里，<br></br>人类更喜欢新颖的方式来获取信息。<br></br>运用Check-look的人工智能虚拟形象，<br></br>让您的故事以互动视觉的方式传遍世界任何一个角落。
          </p>
        </div>
      </div>

      {/* <section className="snap-section flex items-center justify-center">
        <div className={`text-white text-center m-8 leading-relaxed ${isVisible[1] ? 'animate-slide-up' : ''}`} ref={el => sectionRefs.current[1] = el}>
          <h2 className="text-3xl font-bold m-10">
            安全支持
          </h2>
          <p className='text-xl leading-relaxed'>
            我们始终尊重用户的隐私，<br></br>并且严格遵守国家的相关法律法规。
          </p>
        </div>
      </section> */}

      <section className="snap-section flex items-center justify-center">
        <div className={`flex flex-col justify-center items-center text-white text-center m-8 leading-relaxed ${isVisible[1] ? 'animate-slide-up' : ''}`} ref={el => sectionRefs.current[1] = el}>
          <h2 className="text-3xl font-bold mb-20">
            团队核心成员介绍
          </h2>
          <div className='flex flex-row items-center justify-center space-x-20 m-10'>
            <div className="flex flex-col items-center justify-center group" onMouseEnter={() => setHoveredMember('zhuangjingwen')} onMouseLeave={() => setHoveredMember('')}>
              <img src={ZhuangJiongwen} alt="Image4" className="w-48 h-48 rounded-3xl group-hover:border-2 group-hover:border-white group-hover:shadow-white group-hover:shadow-md" />
              <h3 className="text-xl font-bold mt-4">庄煚文</h3> {/* 添加姓名标题 */}
              <h4 className="text-lg font-bold">首席执行官</h4>
            </div>

            <div className="flex flex-col items-center group" onMouseEnter={() => setHoveredMember('zhuangjia')} onMouseLeave={() => setHoveredMember('')}>
              <img src={ZhuangJia} alt="Image5" className="w-48 h-48 rounded-3xl group-hover:border-2 group-hover:border-white group-hover:shadow-white group-hover:shadow-md" />
              <h3 className="text-xl font-bold mt-4">庄稼</h3>
              <h4 className="text-lg font-bold">首席技术官</h4>
            </div>

            <div className="flex flex-col items-center group" onMouseEnter={() => setHoveredMember('wangzhuqing')} onMouseLeave={() => setHoveredMember('')}>
              <img src={WangZhuqing} alt="Image5" className="w-48 h-48 rounded-3xl group-hover:border-2 group-hover:border-white group-hover:shadow-white group-hover:shadow-md" />
              <h3 className="text-xl font-bold mt-4">王竹青</h3>
              <h4 className="text-lg font-bold">品牌艺术总监</h4>
            </div>
          </div>

          <div className='h-32 w-2/3 leading-relaxed text-xl'>
            <div className={`${hoveredMember === 'zhuangjingwen' ? 'block' : 'hidden'}`}>庄煚文：博士、人工智能图像学专家</div>
            <div className={`${hoveredMember === 'zhuangjia' ? 'block' : 'hidden'}`}>庄稼：澳大利亚新南威尔士大学双硕士学位，擅长计算机数据分析和计算机图形学。代表中国（人工智能赛道）出战2024年索契青年科学家项目论坛。全球AI艺术大赛（GAAC）银奖获得者。多次全球学术论坛的演讲者、邀约嘉宾。</div>
            <div className={`${hoveredMember === 'wangzhuqing' ? 'block' : 'hidden'}`}>王竹青：纽约电影学院、华东师范大学双硕士学位，担任多部影视作品的制片人、导演等关键角色，作品屡获佳绩。拥有丰富的影视项目经验，对艺术创作和策划有深刻理解和独到见解。导演作品《环中生环》获得全国数字艺术设计大赛金奖、入围HOLLYWEED电影节。</div>
          </div>

        </div>
      </section>


    </main >
  );
}

export default CompanyPage;
