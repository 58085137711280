import React, { useRef, useState, useEffect } from 'react';
import './HomePage.css'; // 引入新的CSS文件
import FloatingIframeButton from "./FloatingIframeButton";
import SellsImg from './images/销售.jpg'; // 引入图像
import ServiceImg from './images/客户支持.jpg'; // 引入图像
import CompanyTraining from './images/企业培训.jpg'; // 引入图像
import LargeScaleImg from './images/大规模使用.jpg'; // 引入图像
import CustomerFeedbackImg from './images/客户回访.jpg'; // 引入图像
import CellphoneImg from './images/手机端.png'; // 引入图像
import BigScreenImg from './images/大屏幕.png'; // 引入图像
import WebImg from './images/网页端2.png'; // 引入图像
import FloatingImg from './images/网页浮窗.png'; // 引入图像

const ProductPage = () => {
  const videoRef = useRef(null);
  const sectionRefs = useRef([]);
  const [isVisible, setIsVisible] = useState([]);
  const [selectedOption, setSelectedOption] = useState("销售");
  const [isMutedIcon, setIsMutedIcon] = useState(true); // 新增状态变量
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    const handleScroll = () => {
      sectionRefs.current.forEach((ref, index) => {
        const rect = ref.getBoundingClientRect();
        if (rect.top <= window.innerHeight) {
          setIsVisible(prevVisible => {
            const newVisible = [...prevVisible];
            newVisible[index] = true;
            return newVisible;
          });
        } else {
          setIsVisible(prevVisible => {
            const newVisible = [...prevVisible];
            newVisible[index] = false;
            return newVisible;
          });
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMutedIcon(!isMutedIcon); // 切换图标状态
    }
  };
  // 生成渐变颜色的函数
  const generateGradientColor = (index) => {
    const startColor = `hsl(${index * 60}, 100%, 30%)`; // 降低亮度值到30%
    const endColor = `hsl(${index * 60 + 30}, 100%, 30%)`; // 降低亮度值到30%
    return `linear-gradient(to right, ${startColor}, ${endColor})`;
  };

  const OptionContent = ({ selectedOption }) => {
    switch (selectedOption) {
      case '销售':
        return (
          <>
            <img src={SellsImg} alt="Customer Support" className="w-7/12 h-auto ml-10 mr-2 mt-1 rounded-2xl" />
            <div className={`w-30% h-30% mr-10 ml-2 rounded-md flex flex-col items-center justify-center ${isVisible[1] ? 'animate-slide-up' : ''}`} ref={el => sectionRefs.current[1] = el}>
              <h3 className="text-2xl font-bold text-white m-8">销售</h3>
              <p className="text-white">您能够将 Check-look 设定为销售人员，并将其部署至任何支持 H5 的环境中。当用户登录您的网站时，就能与 Check-look 聊天。此外，还能在微信小程序或公众号上直接部署，让我们成为您企业展示页面的重要组成部分，也可以通过二维码或更多样化的方式向您的客户展示。</p>
            </div>
          </>
        );
      case '客户支持':
        return (
          <>
            <img src={ServiceImg} alt="Customer Support" className="w-8/12 h-auto ml-10 mr-2" />
            <div className={`w-30% h-30% mr-10 ml-2 rounded-md flex flex-col items-center justify-center ${isVisible[1] ? 'animate-slide-up' : ''}`} ref={el => sectionRefs.current[1] = el}>
              <h3 className="text-2xl font-bold text-white m-8">客户支持</h3>
              <p className="text-white">客户往往期望任何问题都能得到及时反馈，而我们将成为您的超级助手。它会 7*24 小时不间断工作，引导并帮助客户解决产品问题。</p>
            </div>
          </>
        );
      case '企业培训':
        return (
          <>
            <img src={CompanyTraining} alt="Customer Support" className="w-9/12 h-auto ml-10 mr-2" />
            <div className={`w-30% h-30% mr-10 ml-2 rounded-md flex flex-col items-center justify-center ${isVisible[1] ? 'animate-slide-up' : ''}`} ref={el => sectionRefs.current[1] = el}>
              <h3 className="text-2xl font-bold text-white m-8">企业培训</h3>
              <p className="text-white">您的员工、合作方、客户或者学生，都将拥有一个全能的专业级教练。随时做好准备，全力培养他们，为他们的成功打下基础。</p>
            </div>
          </>
        );
      case '大规模使用':
        return (
          <>
            <img src={LargeScaleImg} alt="Customer Support" className="w-9/12 h-auto ml-10 mr-2" />
            <div className={`w-30% h-30% mr-10 ml-2 rounded-md flex flex-col items-center justify-center ${isVisible[1] ? 'animate-slide-up' : ''}`} ref={el => sectionRefs.current[1] = el}>
              <h3 className="text-2xl font-bold text-white m-8">大规模使用</h3>
              <p className="text-white">我们支持您进行大范围的推广或部署，不会对您企业用户的访问在数量或并发上加以限制，最大程度确保您的声音能被每一位受众听到。无论您需要举办活动、赛事、还是节假日都可以使用我们的数字形象。</p>
            </div>
          </>
        );
      case '客户回访':
        return (
          <>
            <img src={CustomerFeedbackImg} alt="Customer Support" className="w-9/12 h-auto ml-10 mr-2" />
            <div className={`w-30% h-30% mr-10 ml-2 rounded-md flex flex-col items-center justify-center ${isVisible[1] ? 'animate-slide-up' : ''}`} ref={el => sectionRefs.current[1] = el}>
              <h3 className="text-2xl font-bold text-white m-8">客户回访</h3>
              <p className="text-white">您可以把Check-look带入到给客户的邮件或其他联系方式中，Check-look支持您的大范围使用，帮助您提高与潜在客户的沟通效率以及提升产品的转化率。Check-look能够最大程度地降低您的部署成本。我们不会对展示或时长收取费用，而是确保您的每一笔消费都切实用于您企业的具体业务中。</p>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <main className="snap-container">
      <FloatingIframeButton urlRef="https://vc.check-look.com/iframeWrapper?va=UE_you5" buttonImg="/models/UE_you5/cover.png"/>
      <section className="snap-section flex items-center">
        <div className="w-9/12 ml-8 mr-2 video-container relative">
          <video
            ref={videoRef} // 使用 ref 来引用视频元素
            id="myVideo2"
            src="/media/product_video.mp4"
            autoPlay
            loop
            muted
            disablePictureInPicture
            playsInline
            className="w-full h-full object-cover rounded-2xl"
          />
          <button
            className="absolute top-5 left-5 bg-white text-black px-2 py-1 rounded-full"
            onClick={toggleMute} // 使用 toggleMute 函数来切换静音状态
          >
            {isMutedIcon ? '🔇' : '🔊'}
          </button>
        </div>
        <div className={`w-3/12 text-white mr-8 ml-2  ${isVisible[0] ? 'animate-slide-up' : ''}`} ref={el => sectionRefs.current[0] = el}>
          <h2 className="text-3xl font-bold my-5">Check-look 交互式数字形象</h2>
          <p>代替您与您的客户进行实时的交流和互动，借助强大的人工智能算法和拟真的人物声音，用户所得到的将不再是一个冷库的机器对话或一段自动回复，而是一次有温度有情感的对话和聊天。</p>
          <p>Check-look将会用心倾听用户的声音，并且知道如何将一个访问者转变成新的潜在客户的所有步骤。</p>
        </div>
      </section>

      <section className="snap-section flex flex-col h-full " >
        {/* 第一行：标题 */}
        <div className="flex items-center justify-center h-15%">
          <h2 className="text-2xl font-bold text-white m-3" >我们很乐意在以下几个方面为您提供帮助</h2>
        </div>
        {/* 第二行：选项框 */}
        <div className="flex items-center justify-center h-15% mb-2">
          <div className="flex space-x-3 m-4">
            <button
              className="p-2 rounded-md text-white cursor-pointer w-32 hover:translate-y-1 transition-transform duration-100 transform font-bold" // 添加 font-bold 类
              onClick={() => handleOptionClick('销售')}
              style={{ background: generateGradientColor(0) }} // 应用渐变背景
            >
              销售
            </button>
            <button
              className="p-2 rounded-md text-white cursor-pointer w-32 hover:translate-y-1 transition-transform duration-100 transform font-bold" // 添加 font-bold 类
              onClick={() => handleOptionClick('客户支持')}
              style={{ background: generateGradientColor(1) }} // 应用渐变背景
            >
              客户支持
            </button>
            <button
              className="p-2 rounded-md text-white cursor-pointer w-32 hover:translate-y-1 transition-transform duration-100 transform font-bold" // 添加 font-bold 类
              onClick={() => handleOptionClick('企业培训')}
              style={{ background: generateGradientColor(2) }} // 应用渐变背景
            >
              企业培训
            </button>
            <button
              className="p-2 rounded-md text-white cursor-pointer w-32 hover:translate-y-1 transition-transform duration-100 transform font-bold" // 添加 font-bold 类
              onClick={() => handleOptionClick('大规模使用')}
              style={{ background: generateGradientColor(3) }} // 应用渐变背景
            >
              大规模使用
            </button>
            <button
              className="p-2 rounded-md text-white cursor-pointer w-32 hover:translate-y-1 transition-transform duration-100 transform font-bold" // 添加 font-bold 类
              onClick={() => handleOptionClick('客户回访')}
              style={{ background: generateGradientColor(4) }} // 应用渐变背景
            >
              客户回访
            </button>
          </div>
        </div>

        {/* 第三行：根据选项显示不同内容 */}
        <div className="flex h-80%">
          {selectedOption && (
            <OptionContent
              selectedOption={selectedOption}
            />
          )}
        </div>
      </section>

      <section className="snap-section flex items-center justify-center">
        <div className="w-1/2 ml-36 image-container">
          <img src={CellphoneImg} alt="Check-look 产品截图" className="w-80 h-auto rounded-2xl" />
        </div>
        <div className={`w-1/2 text-white mr-8 ml-1 ${isVisible[6] ? 'animate-slide-up' : ''}`} ref={el => sectionRefs.current[6] = el}>
          <h3 className="text-2xl font-bold text-white">
            微信、手机、平板、移动端部署，支持竖屏显示。
          </h3>
        </div>
      </section>

      <section className="snap-section flex items-center">
        <div className="w-9/12 ml-2 image-container">
          <img src={BigScreenImg} alt="Check-look 产品截图" className="w-4/5 h-auto rounded-2xl" />
        </div>
        <div className={`w-3/12 text-white mr-8 ml-1 ${isVisible[2] ? 'animate-slide-up' : ''}`} ref={el => sectionRefs.current[2] = el}>
          <h3 className="text-2xl font-bold text-white">
            可投影到宽屏电视或屏幕，Check-look都会在您受众需要的地方——安全、响应迅速且毫不费力地发挥作用。
          </h3>
        </div>
      </section>

      <section className="snap-section flex items-center">
        <div className="w-9/12 ml-2 image-container">
          <img src={WebImg} alt="Check-look 产品截图" className="w-4/5 h-auto rounded-2xl" />
        </div>
        <div className={`w-3/12 text-white mr-8 ml-1 ${isVisible[3] ? 'animate-slide-up' : ''}`} ref={el => sectionRefs.current[3] = el}>
          <h3 className="text-2xl font-bold text-white">
          网页部署嵌入式显示效果
          </h3>
        </div>
      </section>
      <section className="snap-section flex items-center">
        <div className="w-9/12 ml-2 image-container">
          <img src={FloatingImg} alt="Check-look 产品截图" className="w-4/5 h-auto rounded-2xl" />
        </div>
        <div className={`w-3/12 text-white mr-8 ml-1 ${isVisible[4] ? 'animate-slide-up' : ''}`} ref={el => sectionRefs.current[4] = el}>
          <h3 className="text-2xl font-bold text-white">
          网页部署右下角浮窗显示效果
          </h3>
        </div>
      </section>

      <section className="snap-section flex items-center justify-center">
          <div className={`text-white text-center text-3xl font-bold m-8 leading-relaxed ${isVisible[5] ? 'animate-slide-up' : ''}`} ref={el => sectionRefs.current[5] = el}>
          您需要一个真正的人工智能，<br></br>让我们协助您与您的客户在更深层次上产生联系。<br></br>超越简单的文本，<br></br>使用互动视觉艺术，<br></br>让您的声音传遍整个世界。<br></br>现在让我们开启属于您的Check-look之旅吧。
          </div>
        </section>
    </main>
  );
};
export default ProductPage;