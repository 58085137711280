import React from 'react';
import './HomePage.css'; // 引入新的CSS文件

const ServicePage = () => {
  return (
    <main className="snap-container">
      <section className="snap-section">
        <div className="flex flex-col items-center justify-center h-screen text-white">
          <h1 className="text-3xl font-bold">页面正在更新中。。</h1>
        </div>
      </section>
    </main>
  );
}

export default ServicePage;
