import React, { useEffect, useRef, useState } from 'react';
import './HomePage.css'; // 引入新的CSS文件
import HomepageImg from './images/首页图片.jpg'; // 引入图像

const HomePage = () => {
  const sectionRefs = useRef([]);
  const [isVisible, setIsVisible] = useState([]);
  const videoRef = useRef(null); // 使用 useRef 来引用视频元素
  const [isMuted, setIsMuted] = useState(true); // 新增状态变量

  useEffect(() => {
    const handleScroll = () => {
      sectionRefs.current.forEach((ref, index) => {
        const rect = ref.getBoundingClientRect();
        if (rect.top <= window.innerHeight) {
          setIsVisible(prevVisible => {
            const newVisible = [...prevVisible];
            newVisible[index] = true;
            return newVisible;
          });
        } else {
          setIsVisible(prevVisible => {
            const newVisible = [...prevVisible];
            newVisible[index] = false;
            return newVisible;
          });
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // 定义 toggleMute 函数
  const toggleMute = () => {
    setIsMuted(!isMuted); // 切换 isMuted 状态
  };

  return (
    <>
      <main className="snap-container">
        <section className="snap-section">
          <div className="w-full h-full">
            <video src="/media/CN_OP.mp4" 
            autoPlay 
            loop 
            muted 
            disablePictureInPicture
            playsInline 
            className="w-full h-full object-cover" 
            controls={false} />
          </div>
        </section>

        <section className="snap-section flex items-center justify-center">
          <div className={`text-white text-center text-3xl font-bold mx-10 ${isVisible[0] ? 'animate-slide-up' : ''}`} ref={el => sectionRefs.current[0] = el}>
            借助Check-look无与伦比的部署速度和简便性，为您的企业注入新的生命力，将交易转变为既简单又丰富的体验。
          </div>
        </section>

        <section className="snap-section flex items-center">
          <div className="w-9/12 ml-8 mr-2 image-container relative">
            <img src={HomepageImg} alt="Screenshot" className="w-full h-full object-cover" />
          </div>

          <div className={`w-3/12 text-white mr-8 ml-2 ${isVisible[1] ? 'animate-slide-up' : ''}`} ref={el => sectionRefs.current[1] = el}>
            <h2 className="text-3xl font-bold my-5">有了Check-look，您的业务不仅仅是在沟通，而是在连接您的每一位客户</h2>
            <p>与您一同探索一个浸透式的多样化数字形象世界，通过精心打造的数字形象来吸引您的受众。</p>
          </div>
        </section>

        <section className="snap-section flex items-center">
          <div className="w-9/12 ml-8 mr-2 video-container relative">
            <video
              ref={videoRef} // 使用 ref 来引用视频元素
              id="myVideo2"
              src="/media/首页视频720P.mp4"
              autoPlay
              loop
              disablePictureInPicture
              playsInline
              muted={isMuted} // 使用状态变量来控制视频的静音状态
              className="w-full h-full object-cover rounded-2xl"
            />
            <button
              className="absolute top-5 left-5 bg-white text-black px-2 py-1 rounded-full "
              onClick={toggleMute} // 使用 toggleMute 函数来切换静音状态
            >
              {isMuted ? '🔇' : '🔊'}
            </button>
          </div>
          <div className={`w-3/12 text-white mr-8 ml-2 ${isVisible[2] ? 'animate-slide-up' : ''}`} ref={el => sectionRefs.current[2] = el}>
            <h2 className="text-3xl font-bold my-5">是人工智能而不是一个简单的机器</h2>
            <p>
              您的客户可以通过多种方式随时与您的数字形象进行沟通，使用您的知识库并结合人工智能技术实现人与人的自然对话，让您的客户拥有更好的体验和感受，而不只是痛苦的和机器进行纯文字聊天。
            </p>
          </div>
        </section>

        <footer className="bottom-0 w-full">
          <div className="text-white text-center text-xs">
            @Copyright Check-look &copy; 2024
          </div>
          <div className="text-white text-center text-xs mt-1 mb-5">
          备案号：<a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">沪ICP备2023001254号-2</a>
          </div>
        </footer>
      </main>
    </>
  );
};

export default HomePage;
